import { Controller } from '@hotwired/stimulus';
import consumer from "../channels/consumer"; // adjust the path as needed

export default class extends Controller {
  static values = { id: Number }

  connect() {
    const integratorId = this.idValue;
    if (integratorId) {
      this.channel = consumer.subscriptions.create(
        { channel: "IntegratorMatchStatusChannel", integrator_id: integratorId },
        {
          received: this.handleReceived.bind(this),
          connected: () => { console.log("Connected to IntegratorMatchStatusChannel"); },
          disconnected: () => { console.log("Disconnected from IntegratorMatchStatusChannel"); }
        }
      );
    }
  }

  handleReceived(data) {
    if (data.message === 'finished') {
      window.location.reload();
    }
  }

  disconnect() {
    if (this.channel) {
      this.channel.unsubscribe();
    }
  }
}