import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["timer"]
  static values = {
    startedAt: String,
    duration: Number
  }

  connect() {
    this.updateTimer()
    this.timer = setInterval(() => this.updateTimer(), 1000)
  }

  disconnect() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  updateTimer() {
    const startTime = new Date(this.startedAtValue.replace(' ', 'T'))
    const now = new Date()
    const elapsed = Math.floor((now - startTime) / 1000)
    const remaining = this.durationValue - elapsed

    if (remaining <= 0) {
      window.location.reload()
      return
    }

    const minutes = Math.floor(remaining / 60)
    const seconds = remaining % 60
    this.timerTarget.textContent = `${minutes}:${seconds.toString().padStart(2, '0')}`
  }
}
