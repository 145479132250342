import { Controller } from "@hotwired/stimulus"
import * as ClipboardFunctions from "../lib/ClipboardFunctions.js";
import flash from "../lib/flashes.js";

export default class extends Controller {
  static values = { url: String, text: String, isOtp: Boolean }
  static targets = ["icon", "button"]

  copy(event) {
    event.preventDefault()

    try {
      if (this.textValue) {
        // Direct text copy (like username)
        navigator.clipboard.writeText(this.textValue)
        this.handleUX()
      } else {
        // Synchronous fetch like OTP
        const xhr = new XMLHttpRequest();
        xhr.open("GET", this.urlValue, false); // false = synchronous
        xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
        xhr.send();

        const data = JSON.parse(xhr.responseText);
        const textToCopy = this.isOtpValue ? data.otp : data.password;

        navigator.clipboard.writeText(textToCopy)

        this.handleUX()
        if (this.isOtpValue) {
          flash(`OTP copied. ${data.time_left} seconds left`, "success")
        } else {
          flash("Copied to clipboard", "success")
        }
      }
    } catch (error) {
      console.error("Copy failed:", error)
      flash("Failed to copy to clipboard", "error")
    }
  }

  handleUX () {


    if (!this.hasIconTarget && !this.hasButtonTarget) {
      return
    }

    if (this.hasButtonTarget) {
      var oldHTML = this.buttonTarget.innerHTML
      this.buttonTarget.innerHTML = "Copied!"
      setTimeout(() => {
        this.buttonTarget.innerHTML = oldHTML
      }, 3000)
    } else {
      // this.iconTarget.style = "display: none"
      var oldHTML = this.iconTarget.innerHTML
      this.iconTarget.innerHTML = "<i class='fas fa-check success'></i>"


      setTimeout(() => {
        this.iconTarget.innerHTML = oldHTML
      }, 3000)
    }


  }
}
